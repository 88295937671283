import { reactive } from "vue";

const state = reactive({
  help: false,
  contact: false,
});

const getters = {
  help() {
    return state.help;
  },
  contact() {
    return state.contact;
  },
};

const methods = {
  setState(item, value) {
    if (item === "help") {
      state.help = value;
    } else {
      state.contact = value;
    }
  },
};

export default {
  state,
  methods,
  getters,
};
