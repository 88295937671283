<template>
  <header class="grid relative py-8 px-5 mx-auto max-w-screen-xl lg:px-0">
    <router-link to="/" class="place-self-center">
      <img class="h-48 lg:h-60" src="../assets/Logos/header.jpg" alt="" />
    </router-link>
    <div
      v-if="showLogout()"
      @click="logout()"
      class="
        absolute
        top-16
        bg-brand-blue
        text-white
        px-5
        rounded-md
        py-3
        select-none
        cursor-pointer
      "
    >
      Ausloggen
    </div>
    <div class="space-y-8 absolute top-16 right-0">
      <img
        @click="store.methods.setState('help', true)"
        class="h-12 w-12 cursor-pointer"
        src="../assets/Icons/chat_question.svg"
        alt=""
      />
      <img
        @click="store.methods.setState('contact', true)"
        class="h-12 w-12 cursor-pointer"
        src="../assets/Icons/phone-call.svg"
        alt=""
      />
    </div>
  </header>
</template>

<script>
import { inject } from "vue";
import { useRoute, useRouter } from "vue-router";
import firebase from "firebase";

export default {
  setup() {
    const store = inject("store");
    const route = useRoute();
    const router = useRouter();

    function showLogout() {
      return route.path !== "/login";
    }

    function logout() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          router.push("/login");
        });
    }
    return {
      logout,
      store,
      showLogout,
    };
  },
};
</script>

<style scoped></style>
