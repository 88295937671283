import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import "./index.css";

var firebaseConfig = {
  apiKey: "AIzaSyDt5i4dzR9HNX4mDnBd75aSSfsqCqHGniI",
  authDomain: "outlaw-12c76.firebaseapp.com",
  projectId: "outlaw-12c76",
  storageBucket: "outlaw-12c76.appspot.com",
  messagingSenderId: "908837756812",
  appId: "1:908837756812:web:c23ebdf76f1b93db3980a9",
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

createApp(App).use(router).mount("#app");
