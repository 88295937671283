<template>
  <footer class="pt-20 pb-10 mt-10 px-5 mx-auto max-w-screen-xl lg:px-0">
    <div class="grid grid-cols-2 place-items-center md:grid-cols-5 gap-6">
      <a
        href="https://www.outlaw-diestiftung.de/"
        target="_blank"
        class="border-r-4 px-5 border-brand-red h-20 grid"
        ><img
          class="h-12 place-self-center"
          src="../assets/Logos/Outlaw_Logo.svg"
          alt=""
      /></a>
      <a href="https://www.kkstiftung.de/de/index.htm" target="_blank"
        ><img
          class="h-20"
          src="../assets/Logos/karl_kuebel_stiftung_4c.jpg"
          alt=""
      /></a>
      <a href="https://soziales.hessen.de/" target="_blank"
        ><img
          class="h-20"
          src="../assets/Logos/HMSI_2014_Logo_CMYK_300.jpg"
          alt=""
      /></a>
      <a href="https://www.kinderrechteinstitut.info/" target="_blank"
        ><img
          class="h-20"
          src="../assets/Logos/Logo-Kinderrechteinstitut.png"
          alt=""
      /></a>
      <a href="https://isa-muenster.de/" target="_blank"
        ><img
          class="h-20"
          src="../assets/Logos/ISA-Bildmarke_F_300-rgb.jpg"
          alt=""
      /></a>
    </div>
    <div class="text-center mt-10 space-x-6">
      <a
        href="https://www.kinderrechte-kongress.de/impressum"
        target="_blank"
        class="hover:underline"
        >Impressum</a
      >
      <a
        href="https://www.kinderrechte-kongress.de/datenschutz"
        target="_blank"
        class="hover:underline"
        >Datenschutz</a
      >
    </div>
  </footer>
</template>

<script>
export default {
  name: "footer",
};
</script>

<style scoped></style>
