<template>
  <div>
    <Modal v-for="(modal, index) in modals" :key="index" :item="modal" />
    <Header />
    <router-view />
    <Footer />
  </div>
</template>

<script>
import Modal from "./components/modal.vue";
import Header from "./components/header.vue";
import Footer from "./components/footer.vue";
import { ref, provide, watch } from "vue";
import { useRoute } from "vue-router";
import store from "@/store";

export default {
  components: { Header, Footer, Modal },
  setup() {
    provide("store", store);
    const route = useRoute();

    function isOnLogin() {
      return route.path === "/login";
    }

    watch(isOnLogin, (state) => {
      if (state) {
        store.methods.setState("help", true);
      }
    });

    let modals = ref([
      {
        name: "contact",
        src: require("./assets/Bilder/chris-montgomery-smgTvepind4-unsplash.jpeg"),
        color: "blue",
        title: "kontakt bei technischen fragen",
        text: "<p><b>Technischer Support: </b> <a href='tel:++492512007990' class='hover:underline'>+49 251 200 799 0</a></p>",
      },
      {
        name: "help",
        video: "BHygLcVb920",
        color: "green",
        title: "So geht das.",
        text: "<p>In diesem Video wird Ihnen erklärt, wie Sie die Platform nutzen können und wie sie funktioniert. Sollten Sie noch Fragen haben, stehen wir Ihnen im Chat oder telefonisch zur Verfügung.</p>",
      },
    ]);
    return {
      modals,
    };
  },
};
</script>
